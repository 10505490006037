import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import Chip from "@mui/material/Chip";

import { orderLgsColumns, orderLgsDetailColumns } from "./rawData";
import { useGetOrderLogs } from "../../api/useLogs";
import CustomGrid from "../../components/CustomGrid";
import SearchBar from "../../components/SearchBar/SearchBar";
import CustomDialogBox from "../../components/CustomDialogBox";
import { useLocation } from "react-router-dom";
import TopDialogBox from "../../components/TopDialogBox/TopDialogBox";
import { TopDialogBoxProps } from "../../components/TopDialogBox/TopDialogBox";

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: #44c2b233 !important;
    border: 2px solid #44c2b2 !important;
    margin: "5px";
  }

  .MuiChip-label {
    font: normal normal bold 14px/80px Raleway;
    letter-spacing: 0.42px;
    color: #44c2b2;
    text-transform: capitalize;
  }

  .MuiChip-deleteIcon path {
    color: #44c2b2;
  }
`;

export default function TableSection() {
  const location = useLocation();
  // const [selectedTags, setSelectedTags] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRowData, setSelectedRowData] =
    useState<TopDialogBoxProps | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const segments = location.pathname.split("/");
  const urlSegment = segments[segments.length - 1].replaceAll("%20", " ");

  useEffect(() => {
    urlSegment !== "logs" && setSearchValue([...searchValue, urlSegment]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSegment]);

  const params = new URLSearchParams(window.location.search);
  const searchByParam = params.get("SearchBy")?.split(",");

  useEffect(() => {
    if (searchByParam) {
      setSearchValue(searchValue.concat(searchByParam));
    }
    window.history.replaceState({}, "", "/app/logs");
    urlSegment !== "logs" && setSearchValue([...searchValue, urlSegment]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchParam = searchValue.join(" ");

  const queryParams = searchValue.length > 0 ? searchValue.join(",") : "";
  const searchUrl = queryParams ? `?SearchBy=${queryParams}` : "/app/logs";
  const orderLogs = useGetOrderLogs(searchParam);

  useEffect(() => {
    window.history.replaceState({}, "", searchUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, urlSegment]);

  const handleSearch = () => {
    if (inputValue.trim() !== "") {
      const trimmedInput = inputValue.trim();
      if (!searchValue.includes(trimmedInput)) {
        setSearchValue([...searchValue, trimmedInput]);
      }
      setInputValue("");
    }
  };
  const handleChipDelete = (chipToDelete: string) => {
    setSearchValue(searchValue.filter((chip) => chip !== chipToDelete));
  };
  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };
  //get Row id and Open Dialog Box
  const handleRowClick = (rowData: any) => {
    setDialogOpen(true);
    setSelectedRowData(rowData?.row);
  };
  //Closing Dialog Box
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    searchValue.length > 0 && orderLogs.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  return (
    <>
      <Box>
        <Grid container spacing={2} className="searchSection">
          <Grid item xs={12} md={6} lg={7} xl={8} sx={{ marginBottom: "10px" }}>
            <SearchBar
              value={inputValue}
              onChange={(e: any) => setInputValue(e.target.value)}
              onSearchClick={handleSearch}
              onKeyDown={handleEnterKeyPress}
            />
          </Grid>
        </Grid>
      </Box>
      {searchValue.map((chip, index) => (
        <StyledChip
          key={index}
          label={chip}
          onDelete={() => handleChipDelete(chip)}
          style={{ margin: "0 5px 10px 5px" }}
        />
      ))}
      <CustomGrid
        rows={orderLogs?.data || []}
        sx={{
          height: 600,
          width: "99%",
          position: "static",
          ".MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          ".Mui-odd": { background: "#00000008" },
        }}
        columns={orderLgsColumns}
        handleRowClick={handleRowClick}
        loading={orderLogs?.isFetching}
        showToolbar={true}
        getRowId={(row: any) => row?.order_id}
      />
      <CustomDialogBox open={dialogOpen} onClose={handleCloseDialog}>
        {selectedRowData && (
          <TopDialogBox
            id={selectedRowData.id}
            fullfillment_status={selectedRowData?.fullfillment_status}
            createUpdateLabel="Created"
            isTotal={true}
            isStatus={true}
            userID={selectedRowData?.user_id}
            name={selectedRowData.name}
            userEmail={selectedRowData?.user_principal_name}
            organization={selectedRowData?.customer_display_name}
            created={selectedRowData?.created_timestamp}
            total={
              selectedRowData?.order_total_sale_price
                ? selectedRowData?.order_total_sale_price
                : 400
            }
            innerRows={selectedRowData ? [selectedRowData] : []}
            innerColumns={orderLgsDetailColumns}
          />
        )}
        <CustomGrid
          sx={{
            height: 230,
            width: "99%",
            ".Mui-odd": { background: "#00000008" },
          }}
          rows={selectedRowData ? [selectedRowData] : []}
          columns={orderLgsDetailColumns}
          showToolbar={false}
          getRowId={(row: any) => row?.order_id}
        />
      </CustomDialogBox>
    </>
  );
}
