import MuiBox, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const BoxWrapper = styled(MuiBox)<BoxProps>(
  ({ theme }) =>
    ({
      ".MuiTabs-root": {
        marginBottom:"20px"
      },
      ".MuiBox-root ": {
        border: "0",
      },
      ".MuiTabs-indicator": {
        display: "none",
      },
      ".tabsBtns": {
        textTransform: "capitalize !important",
        width: "120px",
        font: "normal normal 600 16px/80px Raleway !important",
        color: "#333333 !important",
        background: "#E2E2E2",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        marginRight: "10px",
        height: "50px !important",
        padding: "0 !important",
      },
      ".Mui-selected": {
        background: "#EE7623 0% 0%",
        color: "#fff !important",
      },

      ".logs_data_grid .MuiBox-root": {
        padding: "0",
      },
      ".MuiDataGrid-toolbarContainer button ":{
        padding: "4px 5px !important",
        fontSize: "14px !important",
      },
      ".MuiDataGrid-toolbarContainer button:first-child": {
        paddingLeft: "0 !important",
      },
      ".MuiDataGrid-toolbarContainer": {
        marginBottom: "10px",
      },
      ".logs_data_grid .MuiBox-root.css-19kzrtu":{
      position:"relative"
      }
    } as any)
) as (props: BoxProps) => JSX.Element;
