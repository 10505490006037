import { GridCellParams } from "@mui/x-data-grid-pro";

export const CustomCell: React.FC<GridCellParams> = (params) => {
  const value = params.value?.toString(); // Convert value to string

  let cellStyle: React.CSSProperties = {};

  // Apply conditional styling based on cell value
  if (
    value === "Active" ||
    value === "Completed" ||
    value === "Success" ||
    value === "Sent"
  ) {
    cellStyle.color = "#44C2B2";
  } else if (
    value === "Inactive" ||
    value === "Cancelled" ||
    value === "Failure" ||
    value === "Suspended" ||
    value === "Error"
  ) {
    cellStyle.color = "#FF3636";
  } else if (value === "Pending") {
    cellStyle.color = "blue";
  }

  return <div style={cellStyle}>{value}</div>;
};
