import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
} from "@mui/x-data-grid-pro";
import { BoxWrapper } from "./Styled";

interface CustomGridProps {
  rows: any;
  columns: GridColDef[];
  handleRowClick?: (e: any) => void;
  loading?: boolean;
  getRowId?: any;
  showToolbar?: boolean | any;
  columnVisibilityModel?: any;
  sx: {};
}
export default function CustomGrid({
  rows,
  columns,
  handleRowClick,
  loading,
  getRowId,
  showToolbar,
  sx,
  columnVisibilityModel,
}: CustomGridProps) {
  const CustomToolbar = () =>
    showToolbar && (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  const filterColumns = ({
    field,
    columns,
    currentFilters,
  }: FilterColumnsArgs) => {
    const filteredFields = currentFilters?.map((item) => item.field);

    return columns
      .filter(
        (colDef) =>
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))
      )
      .map((column) => column.field);
  };

  const getColumnForNewFilter = ({
    currentFilters,
    columns,
  }: GetColumnForNewFilterArgs) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
      .filter(
        (colDef) => colDef.filterable && !filteredFields.includes(colDef.field)
      )
      .find((colDef) => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
  };

  return (
    <BoxWrapper>
      <DataGridPro
        sx={sx}
        localeText={{
          noRowsLabel: "No data found",
        }}
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        loading={loading}
        pagination
        pageSizeOptions={[25, 50, 100]}
        onRowClick={handleRowClick}
        disableRowSelectionOnClick
        columnVisibilityModel={columnVisibilityModel}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              filterColumns,
            },
            getColumnForNewFilter,
          },
        }}
        initialState={{        
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        rowHeight={48}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
        }
      />
    </BoxWrapper>
  );
}
