/** @format */

import React, { useState, useMemo, ChangeEvent } from "react";
import "./Organization.scss";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";

import TablePagination from "@mui/material/TablePagination";
import {
  stableSort,
  getComparator,
  Order,
} from "../../submodule/components/Tables/Table";
import { TableStyled } from "../../submodule/components/Tables/TableStyles";
import EnhancedTableHead from "../../submodule/components/Tables/TableHead";
import DownloadButton from "../../submodule/components/DownloadButton/DownloadButton";
import SearchBar from "../../submodule/components/SearchBar/SearchBar";
import { useGetOrganizations } from "../../api/useOrganizations";
import { IOrganization } from "../../shared/types";
import { convertGMTtoDate, resolveDynamicUrl } from "../../shared/utils";
import { APP_ROUTES } from "../../constants/appRoutes";
import { ActiveStatusData, OrganizationColumnsData } from "../../shared/data";
import Typography from "@mui/material/Typography";
import Loader from "../../components/Loader/Loader";
import { Button } from "@mui/material";

interface HeadCell {
  disablePadding: boolean;
  id: keyof IOrganization;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "display_name",
    numeric: false,
    disablePadding: true,
    label: "Organization",
  },
  {
    id: "is_active",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: true,
    label: "Created",
  },
  {
    id: "customer_guid",
    numeric: false,
    disablePadding: true,
    label: "Customer Id",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

const OrganizationPage = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IOrganization>("display_name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchText, setSearchText] = useState("");
  const organizations = useGetOrganizations();

  const data = useMemo(() => {
    if (searchText.trim().length) {
      return organizations.data!.filter((row) =>
        row.display_name.toLowerCase().includes(searchText.toLowerCase().trim())
      );
    } else return organizations.data!;
  }, [searchText, organizations.data]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof IOrganization
  ) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const isEmptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const columns = OrganizationColumnsData;

  const clickableRow = (row: IOrganization) => {
    navigate(
      resolveDynamicUrl(APP_ROUTES.ORGANIZATION_DETAIL, {
        organizationId: row.customer_guid,
      }),
      {
        state: row,
      }
    );
  };
  const viewLogsHandler = (row: IOrganization) => {
    navigate(
      resolveDynamicUrl(APP_ROUTES.ORDERLOGS, {
        organizationId: row.display_name,
      }),
      {
        state: row,
      }
    );
  };

  const onSearchTextHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  if (organizations.isFetching) {
    return <Loader />;
  }

  return (
    <div>
      <Box
        className="d-md-flex justify-content-md-end align-items-md-center"
        sx={{ marginBottom: `30px` }}
      >
        <SearchBar value={searchText} onChange={onSearchTextHandler} />
        <DownloadButton
          rows={data}
          columns={columns}
          filename="organization.csv"
          text="Download"
        />
      </Box>
      <Box>
        {!data.length ? (
          <Typography
            sx={{ textAlign: "center", marginTop: "16px" }}
            variant="h6"
          >
            No data found
          </Typography>
        ) : (
          <></>
        )}
        {data.length ? (
          <Paper sx={{ boxShadow: "none" }}>
            <TableContainer
              className="organization-table-container"
              style={{ marginTop: "30px" }}
            >
              <TableStyled stickyHeader aria-label="sticky table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={onSortHandler}
                  rowCount={data.length}
                  headCells={headCells}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.customer_guid}
                          // sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            onClick={() => clickableRow(row)}
                            className="organizationTitle"
                            sx={{ cursor: "pointer" }}
                          >
                            {row.display_name}
                          </TableCell>
                          <TableCell
                            className={`ms-${
                              row.is_active ? "active" : "suspend"
                            }`}
                            sx={{
                              color: row.is_active ? "#44C2B2" : "#FF3636",
                            }}
                          >
                            {ActiveStatusData[Number(row.is_active)]}
                          </TableCell>
                          <TableCell>
                            {convertGMTtoDate(row.created_at)}
                          </TableCell>
                          <TableCell>{row.customer_guid}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => viewLogsHandler(row)}
                              sx={{
                                color: "#fff",
                                background: "#0A7ECA80",
                                textTransform: "capitalize",
                                border: "1px solid #0A7ECA",
                                borderRadius: "38px",
                                "&:hover": { background: "#0A7ECA80" },
                                fontSize: { xl: "16px" },
                                height: { xl: "40px" },
                                padding: { xl: "5px 25px" },
                              }}
                            >
                              Orders
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {isEmptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter></TableFooter>
              </TableStyled>
            </TableContainer>
            <TablePagination
              sx={{
                background: "#e3e3e3",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        ) : null}
      </Box>
    </div>
  );
};

export default OrganizationPage;
