/** @format */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getAuthorizationHeader } from "../../../constants";
import {  CircularProgress,Box } from "@mui/material";


const Start = ({ app, postLoginRedirectPath }: { app: string, postLoginRedirectPath: string }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // <editor-fold desc="handle the redirect from a sign-in operation">
  useEffect(() => {
    // extract the session guid from the query string
    const queryParams = new URLSearchParams(window.location.search);
    const sessionGuid = queryParams.get("s");

    if (sessionGuid) {
      sessionStorage.setItem("session_guid", sessionGuid);

      // use the session guid to get the user's profile
      axios
        .get(
          `${process.env.REACT_APP_API_BASE}/sso/v1/profile/` + app,
          { headers: getAuthorizationHeader() })
        .then(function (response) {
          if (response.data) {
            setIsLoggedIn(true);

            sessionStorage.setItem("first_name", response.data.first_name);
            sessionStorage.setItem("last_name", response.data.last_name);
            sessionStorage.setItem("display_name", response.data.display_name);
            sessionStorage.setItem("email", response.data.email);
            sessionStorage.setItem("customer_name", response.data.customer_name);
            sessionStorage.setItem("domain_name", response.data.domain_name);
            sessionStorage.setItem("is_active", response.data.is_active);

            navigate(postLoginRedirectPath);
          } else {
            setIsLoggedIn(false);
            sessionStorage.clear();

            navigate("./signin?e=unauthorized");
          }
        })
        .catch(function (error) {
          setIsLoggedIn(false);
          sessionStorage.clear();

          navigate("./signin?e=unauthorized");
        });
    }
  }, [app, isLoggedIn, navigate, postLoginRedirectPath]);
  // </editor-fold>

  // <editor-fold desc="handle refreshing the session on user activity">
  function throttle<T extends (...args: any[]) => any>(func: T, limit: number): T {
    let lastCall = 0;
    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
      const now = Date.now();
      if (now - lastCall >= limit) {
        lastCall = now
        func.apply(this, args);
      }
    } as T;
  }

  const handleUserActivity = throttle(async () => {
    if (!isLoggedIn) {
      return;
    }

    await axios.get(
        `${process.env.REACT_APP_API_BASE}/sso/v1/refresh/` + app,
        { headers: getAuthorizationHeader() }
    )
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem("expires_on", response.data.expires_on);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        setIsLoggedIn(false);
        sessionStorage.clear();
        navigate("./signin?e=timeout");
      }
    });
  }, 60 * 1000); // throttle refresh call interval: 1 minute

  useEffect(() => {
    if (!isLoggedIn) return;

    const events = [
      "mousemove", "keydown", "scroll", "touchstart",
      "mousedown", "touchmove", "touchend", "wheel"
    ];

    events.forEach((event) => {
      window.addEventListener(event, handleUserActivity);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
    };
  }, [isLoggedIn, handleUserActivity]);
  // </editor-fold>

  return (
    <Box sx={{display:'flex', alignItems:'center',justifyContent:'center', height:'100vh'}}>
      <CircularProgress />
    </Box>
  )
};

export default Start;
