import MuiBox, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const BoxWrapper = styled(MuiBox)<BoxProps>(
  ({ theme }) =>
    ({
      // display: "flex",
      // justifyContent: "space-between",
      ".MuiDataGrid-main": { fontFamily: "Raleway" },
      ".MuiBox-root": { padding: "0 !important" },
      ".MuiDataGrid-columnHeaders": {
        backgroundColor: "#9BA4AF",
        color: "#fff",
      },
      // ".MuiDataGrid-root": {
      //   border: "0",
      // },
      // border: "0",
      borderRadius: "50px",
      ".MuiDataGrid-footerContainer": {
        backgroundColor: "#e3e3e3",
        color: "#000000de",
      },
      ".MuiDataGrid-columnSeparator": {
        display: "none !important",
      },
      "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
        borderBottom: "none",
      },
      ".MuiDataGrid-cell ": {
        borderBottom: "0 !important",
      },
      ".MuiDataGrid-row.Mui-selected": {
        boxShadow: "0px 3px 6px #0000001C",
        background: "#E2E2E2 !important",
        color: "#333333 !important",
      },
      ".MuiDataGrid-toolbarContainer": {
        position: "absolute",
        top: "20px",
        right: "0px",
      },
      ".MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
        fontSize: "16px",
      },
      ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus":
        {
          outline: "none !important",
        },
      ".MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered ": {
        backgroundColor: "rgba(226, 226, 226, 1) !important",
      },
      [theme.breakpoints.down("sm")]: {
        ".MuiDataGrid-toolbarContainer": {
          position: "relative",
          top: "0px",
        },
      },
      [theme.breakpoints.down("md")]: {
        ".MuiDataGrid-toolbarContainer": {
          position: "relative",
          top: "0px",
        },
      },
      [theme.breakpoints.down("xl")]: {
        ".MuiDataGrid-toolbarContainer .MuiButtonBase-root": {
          fontSize: "14px",
        },
      },
    } as any)
) as (props: BoxProps) => JSX.Element;
