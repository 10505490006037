import { useQuery, UseQueryOptions } from "react-query";
import { API_CACHE_KEYS, ApiRoutes } from "../constants/apiRoutes";
import { CHANGELOGS, LOGS } from "../shared/types";
import axios from "axios";

//Function with query param added

export const useGetOrderLogs = (
  SearchBy?: string | string[],
  options?: UseQueryOptions<LOGS[], null, LOGS[]>
) => {
  return useQuery<LOGS[], null, LOGS[]>(
    `${SearchBy}|API_CACHE_KEYS.ORDERLOGS`,
    async () => {
      const { data } = await axios.get(ApiRoutes.orderLogs, {
        params: { limit: 1000, ...(SearchBy && { SearchBy: SearchBy }) },
      });
      return data;
    },
    { ...options, placeholderData: [], retry: 0 }
  );
};

export const useGetChangeLogs = (
  SearchBy?: string | string[],
  options?: UseQueryOptions<CHANGELOGS[], null, CHANGELOGS[]>
) => {
  return useQuery<CHANGELOGS[], null, CHANGELOGS[]>(
    API_CACHE_KEYS.CHANGELOGS,
    async () => {
      const { data } = await axios.get(ApiRoutes.changeLogs, {
        params: { limit: 1000, ...(SearchBy && { SearchBy: SearchBy }) },
      });
      return data;
    },
    { ...options, placeholderData: [], retry: 0 }
  );
};
