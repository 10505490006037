export const resolveDynamicUrl = (dynamicUrl: string, dynamicProperties: { [key: string]: string }) => {
  for (const key in dynamicProperties) {
    dynamicUrl = dynamicUrl.replace(`{${key}}`, dynamicProperties[key])
  }
  return dynamicUrl;
}

export function convertGMTtoDateTime(gmtDate: string | number) {
  const dateTime = new Date(gmtDate);
  const dateTimeString = dateTime.toLocaleString();
  return dateTimeString;
}

export function convertGMTtoDate(gmtDate: string | number) {
  const createdDate = new Date(gmtDate);
  const dateString = createdDate.toLocaleDateString();
  return dateString;
}
