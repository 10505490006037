import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { GridWrapper } from "./Styled";
import DownloadButton from "../DownloadButton/DownloadButton";

export interface TopDialogBoxProps {
  order_total_sale_price?: any;
  user_display_name?:string;
  sale_price?: number;
  created_timestamp?: string | undefined;
  user_id?: string | number;
  user_first_name?: string | number | undefined;
  change_timestamp?: string | undefined;
  customer_display_name?: string;
  user_principal_name?: string | undefined;
  id: number;
  createUpdateLabel: string;
  userID?: string | number;
  name?: string;
  isTotal: boolean;
  isStatus: boolean;
  userEmail?: string;
  organization?: string;
  created?: string;
  changed?: string;
  fullfillment_status?: "Completed" | "Cancelled" | "Pending" | null;
  total?: number;
  item?: string;
  innerRows?: any;
  innerColumns?: any;
}

export default function TopDialogBox({
  fullfillment_status,
  createUpdateLabel,
  isTotal,
  isStatus,
  userID,
  user_first_name,
  name,
  userEmail,
  organization,
  created,
  total,
  changed,
  item,
  innerRows,
  innerColumns,
}: TopDialogBoxProps) {
  const statusColors = {
    Completed: "#44c2b2",
    Cancelled: "#ff3636",
    Pending: "orange",
  };
  const textColor = fullfillment_status
    ? statusColors[fullfillment_status]
    : undefined;

  return (
    <GridWrapper container spacing={2} sx={{ alignItems: "center" }} mb={3}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }} gap={2}>
        <Typography variant="h3">{userID} </Typography>
          {/* {!changed ? (
            <Typography variant="h3">{item}</Typography>
          ) : (
            <Typography variant="h3">{userID} </Typography>
          )} */}
          {isStatus && (
            <Typography sx={{ color: textColor }}>
              ({fullfillment_status})
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box sx={{ display: "flex", alignItems: "center" }} gap={2}>
          <Typography>
            <strong>Organization: </strong>
            {organization}
          </Typography>
          <Typography>
            <strong>User </strong>
            {name} ({userEmail})
          </Typography>
          {changed ? (
            <Typography>
              <strong>{createUpdateLabel} </strong>
              {changed}
            </Typography>
          ) : (
            <Typography>
              <strong>{createUpdateLabel} </strong>
              {created}
            </Typography>
          )}
          {isTotal && (
            <Typography>
              <strong>Total: </strong>${total?.toFixed(2)} USD
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <DownloadButton
          rows={innerRows}
          columns={innerColumns}
          text="Export CSV"
          filename="organization.csv"
        />
      </Grid>
    </GridWrapper>
  );
}
