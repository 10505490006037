/** @format */

import { Routes as Router, Route, BrowserRouter } from "react-router-dom";
import Layout from "./submodule/components/admin/Layout/Layout";
import OrganizationPage from "./pages/OrganizationPage/OrganizationPage";
import OrganizationDetailPage from "./pages/OrganizationDetailPage/OrganizationDetailPage";
import NotfoundPage from "./submodule/pages/NotFoundPage/NotfoundPage";
import Start from "./submodule/pages/Start/Start";
import LoginPage from "./pages/LoginPage/LoginPage";
import SettingPage from "./pages/SettingPage/SettingPage";
import Logs from "./pages/Logs";

const Routes = () => {
  return (
    <BrowserRouter>
      <Router>
        <Route path="" element={<LoginPage userType="admin" />} />
        <Route path="*" element={<NotfoundPage />} />
        <Route
          path="/app/start"
          element={
            <Start app="cspadmin" postLoginRedirectPath="/app/organizations" />
          }
        />
        <Route path="/app/signin" element={<LoginPage userType="admin" />} />
        <Route path="/app/" element={<Layout app="cspadmin" />}>
          <Route path="/app/organizations" element={<OrganizationPage />} />
          <Route
            path="/app/organization/detail/:organizationId"
            element={<OrganizationDetailPage />}
          />
          <Route path="/app/setting" element={<SettingPage />} />
          <Route path="/app/logs" element={<Logs />} />
          <Route path="/app/logs/:organizationId" element={<Logs />} />
        </Route>
      </Router>
    </BrowserRouter>
  );
};

export default Routes;
