import React, {useState} from 'react';
import Switch from '@mui/material/Switch';
import {useGetSettings, useUpdateSetting} from "../../api/useSettings";
import Loader from "../../components/Loader/Loader";
import {IApp} from "../../shared/types";
import AlertMessage from "../../submodule/components/AlertMessage/AlertMessage";
import {List, ListItem, ListItemIcon, ListItemText, ListSubheader, Divider} from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {convertGMTtoDateTime} from "../../shared/utils";

const SettingPage = () => {
  const [messageAlert, setMessageAlert] = useState<{ type: string, message: string } | null>(null);
  const settings = useGetSettings();
  const updateSetting = useUpdateSetting();

  const data = settings.data || [];

  const onTogglePurchase = (item: IApp, isAllowed: number) => {
    updateSetting.mutate({ appId: item.app_guid, data: { allow_order: isAllowed } }, {
      onSuccess() {
        setMessageAlert({ message: "The settings has been updated", type: "success" });
      },
      onError() {
        setMessageAlert({ message: "Failed to update the settings. Try again!", type: "error" })
      }
    });
  }

  const isLoading = settings.isFetching || updateSetting.isLoading;

  return (
    <div>
      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        subheader={<ListSubheader className="d-inline-flex align-items-center">Allow Order&nbsp;{isLoading && (<Loader size="1em" />)}</ListSubheader>}
      >
        {data.map((item) => (
          <>
            <Divider />
            <ListItem key={item.app_guid}>
              <ListItemIcon>
                { item.allow_order ? <TaskAltIcon />: <ReportIcon /> }
              </ListItemIcon>
              <ListItemText primary={item.name} secondary={convertGMTtoDateTime(item.created_at)} sx={{ textTransform: "capitalize" }} />
              <Switch
                edge="end"
                defaultChecked={item.allow_order}
                disabled={isLoading}
                onChange={(e) => onTogglePurchase(item, Number(e.target.checked))}
              />
            </ListItem>
          </>
        ))}
      </List>
      {messageAlert && (AlertMessage(true, messageAlert.message, messageAlert.type, () => setMessageAlert(null)))}
    </div>
  );
};

export default SettingPage;
