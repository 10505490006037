import { CircularProgress } from '@mui/material';
import React from 'react';
import {CircularProgressProps} from "@mui/material/CircularProgress/CircularProgress";

const Loader = (props: CircularProgressProps) => {
  return (
    <div className="d-flex justify-content-center">
      <CircularProgress color="primary" size="2rem" {...props} />
    </div>
  );
};

export default Loader;
