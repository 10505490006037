import React, { ReactNode } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
interface CustomDialogBoxProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

function CustomDialogBox({ open, onClose, children }: CustomDialogBoxProps) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="dialogBox"
      sx={{ width: "100%",margin: "auto",".MuiPaper-root":{width: "100%",maxWidth: "80%"}}}
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 30,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelIcon className="close-btn" />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialogBox;
