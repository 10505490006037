import { styled } from "@mui/material/styles";
import Grid, { GridProps } from "@mui/material/Grid";

export const GridWrapper = styled(Grid)<GridProps>(({ theme }) => ({
  ".MuiGrid-roo": {
    alignItems: "center",
  },
  ".MuiTypography-h3": {
    fontFamily: "Raleway",
    fontWeight: "bold",
    fontSize: "42px",
  },
  ".MuiGrid-item": {
    paddingTop: "0 !important",
  },
})) as (props: GridProps) => JSX.Element;
