import axios from "axios";
import {useMutation, useQuery, useQueryClient, UseQueryOptions} from "react-query";
import {IApp, IAppUpdate} from "../shared/types";
import {API_CACHE_KEYS, ApiRoutes} from "../constants/apiRoutes";
import {resolveDynamicUrl} from "../shared/utils";

export const useGetSettings = (options?: UseQueryOptions<IApp[], null, IApp[]>) => {
  return useQuery<IApp[], null, IApp[]>(API_CACHE_KEYS.APP, async () => {
    const {data} = await axios.get(ApiRoutes.apps);
    return data;
  }, { ...options });
}


export const useUpdateSetting = () => {
  const queryClient = useQueryClient();
  return useMutation(({ appId, data }: { appId: string, data: IAppUpdate }) => {
    return axios.post(resolveDynamicUrl(ApiRoutes.updateApp, { appId }), data);
  }, {
    onSuccess() {
      queryClient.invalidateQueries(API_CACHE_KEYS.APP);
    }
  })
}
