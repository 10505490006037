import React from "react";
import LogsTabs from "../../components/LogsTabs/LogsTabs";
import OrderLogs from "./OrderLogs";
import ChangeLogs from "./ChangeLogs";

export default function Logs(props: any) {
  const tabs = [
    { label: "Orders", content: <OrderLogs /> },
    { label: "Changes", content: <ChangeLogs /> },
  ];
  return (
    <div>
      <LogsTabs tabs={tabs} />
    </div>
  );
}
