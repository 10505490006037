import { CustomCell } from "../../components/CustomCell/customCellFunc";

export const rows = [
  {
    id: 1,
    item: "Product A",
    item_type: "Type A",
    name: "John Doe",
    promotion: "Summer Sale",
    special_offers: "50% off",
    term_duration: "6 months",
    quantity: 100,
    offer_id: "A123",
    fullfillment_status: "Pending",
    end_date_alignment: "2023-08-09",
    price: 50.99,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 2,
    item: "Product B",
    item_type: "Type B",
    name: "Jane Smith",
    promotion: "Back to School",
    special_offers: "Free Shipping",
    term_duration: "12 months",
    quantity: 200,
    offer_id: "B456",
    fullfillment_status: "Completed",
    end_date_alignment: "2023-08-09",
    price: 75.49,
    organization: "Dell",
    updated: "2023-08-09",
    // status: "Pending",

    created: "2023-08-09",
  },
  {
    id: 3,
    item: "Product A",
    item_type: "Type A",
    name: "John Doe",
    promotion: "Summer Sale",
    special_offers: "50% off",
    term_duration: "6 months",
    quantity: 100,
    offer_id: "A123",
    fullfillment_status: "Pending",
    end_date_alignment: "2023-08-09",
    price: 50.99,
    organization: "Hp",
    updated: "2023-08-09",
    // status: "Pending",

    created: "2023-08-09",
  },
  {
    id: 4,
    item: "Product B",
    item_type: "Type B",
    name: "Jane Smith",
    promotion: "Back to School",
    special_offers: "Free Shipping",
    term_duration: "12 months",
    quantity: 200,
    offer_id: "B456",
    fullfillment_status: "Cancelled",
    end_date_alignment: "2023-08-09",
    price: 75.49,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 5,
    item: "Product A",
    item_type: "Type A",
    name: "John Doe",
    promotion: "Summer Sale",
    special_offers: "50% off",
    term_duration: "6 months",
    quantity: 100,
    offer_id: "A123",
    fullfillment_status: "Pending",
    end_date_alignment: "2023-08-09",
    price: 50.99,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 6,
    item: "Product B",
    item_type: "Type B",
    name: "Jane Smith",
    promotion: "Back to School",
    special_offers: "Free Shipping",
    term_duration: "12 months",
    quantity: 200,
    offer_id: "B456",
    fullfillment_status: "Completed",
    end_date_alignment: "2023-08-09",
    price: 75.49,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 7,
    item: "Product A",
    item_type: "Type A",
    name: "John Doe",
    promotion: "Summer Sale",
    special_offers: "50% off",
    term_duration: "6 months",
    quantity: 100,
    offer_id: "A123",
    fullfillment_status: "Pending",
    end_date_alignment: "2023-08-09",
    price: 50.99,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 8,
    item: "Product B",
    item_type: "Type B",
    name: "Jane Smith",
    promotion: "Back to School",
    special_offers: "Free Shipping",
    term_duration: "12 months",
    quantity: 200,
    offer_id: "B456",
    fullfillment_status: "Completed",
    end_date_alignment: "2023-08-09",
    price: 75.49,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 9,
    item: "Product A",
    item_type: "Type A",
    name: "John Doe",
    promotion: "Summer Sale",
    special_offers: "50% off",
    term_duration: "6 months",
    quantity: 100,
    offer_id: "A123",
    fullfillment_status: "Pending",
    end_date_alignment: "2023-08-09",
    price: 50.99,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 10,
    item: "Product B",
    item_type: "Type B",
    name: "Jane Smith",
    promotion: "Back to School",
    special_offers: "Free Shipping",
    term_duration: "12 months",
    quantity: 200,
    offer_id: "B456",
    fullfillment_status: "Completed",
    end_date_alignment: "2023-08-09",
    price: 75.49,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 11,
    item: "Product A",
    item_type: "Type A",
    name: "John Doe",
    promotion: "Summer Sale",
    special_offers: "50% off",
    term_duration: "6 months",
    quantity: 100,
    offer_id: "A123",
    fullfillment_status: "Pending",
    end_date_alignment: "2023-08-09",
    price: 50.99,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 12,
    item: "Product B",
    item_type: "Type B",
    name: "Jane Smith",
    promotion: "Back to School",
    special_offers: "Free Shipping",
    term_duration: "12 months",
    quantity: 200,
    offer_id: "B456",
    fullfillment_status: "Completed",
    end_date_alignment: "2023-08-09",
    price: 75.49,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 13,
    item: "Product A",
    item_type: "Type A",
    name: "John Doe",
    promotion: "Summer Sale",
    special_offers: "50% off",
    term_duration: "6 months",
    quantity: 100,
    offer_id: "A123",
    fullfillment_status: "Pending",
    end_date_alignment: "2023-08-09",
    price: 50.99,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 14,
    item: "Product B",
    item_type: "Type B",
    name: "Jane Smith",
    promotion: "Back to School",
    special_offers: "Free Shipping",
    term_duration: "12 months",
    quantity: 200,
    offer_id: "B456",
    fullfillment_status: "Completed",
    end_date_alignment: "2023-08-09",
    price: 75.49,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 15,
    item: "Product A",
    item_type: "Type A",
    name: "John Doe",
    promotion: "Summer Sale",
    special_offers: "50% off",
    term_duration: "6 months",
    quantity: 100,
    offer_id: "A123",
    fullfillment_status: "Pending",
    end_date_alignment: "2023-08-09",
    price: 50.99,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
  {
    id: 16,
    item: "Product B",
    item_type: "Type B",
    name: "Jane Smith",
    promotion: "Back to School",
    special_offers: "Free Shipping",
    term_duration: "12 months",
    quantity: 200,
    offer_id: "B456",
    fullfillment_status: "Completed",
    end_date_alignment: "2023-08-09",
    price: 75.49,
    organization: "Microsoft",
    updated: "2023-08-09",
    // status: "Completed",
    created: "2023-08-09",
  },
];

export const changeLogRows = [
  {
    id: 1,
    organization: "Microsoft",
    name: "Alice Meyer",
    item: "Microsoft 365 F1",
    changed: "2023-08-09",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
  {
    id: 2,
    organization: "Google",
    name: "Rachel Richards",
    item: "Microsoft 365",
    changed: "2023-08-08",
    type: "Quantity",
    old: 22,
    new: 400,
  },
  {
    id: 3,
    organization: "Facebook",
    name: "Nicholas Roberts",
    item: "Microsoft 365",
    changed: "2023-08-07",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
  {
    id: 4,
    organization: "Microsoft",
    name: "Alice Meyer",
    item: "Microsoft 365 F1",
    changed: "2023-08-09",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
  {
    id: 5,
    organization: "Google",
    name: "Rachel Richards",
    item: "Microsoft 365",
    changed: "2023-08-08",
    type: "Quantity",
    old: 22,
    new: 400,
  },
  {
    id: 6,
    organization: "Facebook",
    name: "Nicholas Roberts",
    item: "Microsoft 365",
    changed: "2023-08-07",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
  {
    id: 7,
    organization: "Microsoft",
    name: "Alice Meyer",
    item: "Microsoft 365 F1",
    changed: "2023-08-09",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
  {
    id: 8,
    organization: "Google",
    name: "Rachel Richards",
    item: "Microsoft 365",
    changed: "2023-08-08",
    type: "Quantity",
    old: 22,
    new: 400,
  },
  {
    id: 9,
    organization: "Facebook",
    name: "Nicholas Roberts",
    item: "Microsoft 365",
    changed: "2023-08-07",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
  {
    id: 10,
    organization: "Microsoft",
    name: "Alice Meyer",
    item: "Microsoft 365 F1",
    changed: "2023-08-09",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
  {
    id: 11,
    organization: "Google",
    name: "Rachel Richards",
    item: "Microsoft 365",
    changed: "2023-08-08",
    type: "Quantity",
    old: 22,
    new: 400,
  },
  {
    id: 12,
    organization: "Facebook",
    name: "Nicholas Roberts",
    item: "Microsoft 365",
    changed: "2023-08-07",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
  {
    id: 13,
    organization: "Microsoft",
    name: "Alice Meyer",
    item: "Microsoft 365 F1",
    changed: "2023-08-09",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
  {
    id: 14,
    organization: "Google",
    name: "Rachel Richards",
    item: "Microsoft 365",
    changed: "2023-08-08",
    type: "Quantity",
    old: 22,
    new: 400,
  },
  {
    id: 15,
    organization: "Facebook",
    name: "Nicholas Roberts",
    item: "Microsoft 365",
    changed: "2023-08-07",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
];

export const innerChangeLogRows = [
  {
    id: 1,
    organization: "Microsoft",
    name: "Alice Meyer",
    item: "Microsoft 365 F1",
    changed: "2023-08-09",
    type: "Status",
    old: "Active",
    new: "Inactive",
  },
];

export const orderLgsColumns = [
  {
    field: "order_log_id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "customer_display_name",
    headerName: "Organization",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "friendly_name",
    headerName: "Product",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "user_display_name",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "created_timestamp",
    headerName: "Updated",
    flex: 1,
    minWidth: 150,
  },

  {
    field: "fullfillment_status",
    headerName: "Status",
    flex: 1,
    minWidth: 150,
    renderCell: CustomCell,
    valueGetter: (params: any) => {
      return `${params.row.order_error_description ? "Failure" : "Success"}`;
    },
  },
  {
    field: "order_status",
    headerName: "Order Status",
    flex: 1,
    minWidth: 150,
    renderCell: CustomCell,
    valueGetter: (params: any) => {
      return params.row.order_status === "error" ? "Error" : "Sent";
    },
  },
];

export const changeLogColumn = [
  {
    field: "subscription_log_id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "customer_display_name",
    headerName: "Organization",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "friendly_name",
    headerName: "Product",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "user_display_name",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "change_timestamp",
    headerName: "Changed",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "new_status",
    headerName: "Status",
    flex: 1,
    minWidth: 150,
    renderCell: CustomCell,
  },
  {
    field: "new_quantity",
    headerName: "Quantity",
    flex: 1,
    minWidth: 150,
    renderCell: CustomCell,
  },
  {
    field: "new_price",
    headerName: "Price",
    flex: 1,
    minWidth: 150,
  },
];

export const innerChangeLogColumn = [
  {
    field: "subscription_log_id",
    headerName: "ID",
    flex: 1,
    minWidth: 50,
    maxWidth: 50,
  },
  {
    field: "customer_display_name",
    headerName: "Organization",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "friendly_name",
    headerName: "Product",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "user_display_name",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "old_status",
    headerName: "Old Status",
    flex: 1,
    minWidth: 150,
    renderCell: CustomCell,
  },
  {
    field: "new_status",
    headerName: "New Status",
    flex: 1,
    minWidth: 150,
    renderCell: CustomCell,
  },
  {
    field: "old_quantity",
    headerName: "Old Quantity",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "new_quantity",
    headerName: "New Quantity",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "old_price",
    headerName: "Old Price",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "new_price",
    headerName: "New Price",
    flex: 1,
    minWidth: 150,
  },
];

export const orderLgsDetailColumns = [
  {
    field: "catalog_item_id",
    headerName: "Item Name",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "item_type",
    headerName: "Item Type",
    flex: 1,
    minWidth: 100,
  },
  // {
  //   field: "name",
  //   headerName: "Name",
  //   flex: 1,
  //   minWidth: 100,
  // },
  {
    field: "promotion_id",
    headerName: "Promotion",
    flex: 1,
    minWidth: 130,
  },
  {
    field: "special_offers",
    headerName: "Special Offers",
    flex: 1,
    minWidth: 130,
  },
  {
    field: "term_duration",
    headerName: "Term Duration",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
    minWidth: 50,
  },
  // {
  //   field: "currency",
  //   headerName: "Currency",
  //   flex: 1,
  //   minWidth: 100,
  // },
  {
    field: "offer_id",
    headerName: "Offer ID",
    flex: 1,
    minWidth: 50,
  },
  {
    field: "fullfillment_status",
    headerName: "Fullfillment Status",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "custom_term_end_date",
    headerName: "End Date Alignment",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "sale_price",
    headerName: "Price",
    flex: 1,
    minWidth: 100,
    valueGetter: (params: any) => {
      return params.row.sale_price?.toFixed(2);
    },
  },
  {
    field: "order_status",
    headerName: "Order Status",
    flex: 1,
    minWidth: 100,
    renderCell: CustomCell,
    valueGetter: (params: any) => {
      return params.row.order_status === "error" ? "Error" : "Sent";
    },
  },
];

export const innerRow = [
  {
    id: 1,
    item: "Product A",
    item_type: "Type A",
    name: "John Doe",
    promotion: "Summer Sale",
    special_offers: "50% off",
    term_duration: "6 months",
    quantity: 100,
    currency: "USD",
    offer_id: "A123",
    fullfillment_status: "Pending",
    end_date_alignment: "2023-08-09",
    price: 50.99,
    organization: "Microsoft",
    updated: "2023-08-09",
    status: "Completed",
  },
  {
    id: 2,
    item: "Product B",
    item_type: "Type B",
    name: "Jane Smith",
    promotion: "Back to School",
    special_offers: "Free Shipping",
    term_duration: "12 months",
    quantity: 200,
    currency: "EUR",
    offer_id: "B456",
    fullfillment_status: "Completed",
    end_date_alignment: "2023-08-09",
    price: 75.49,
    organization: "Microsoft",
    updated: "2023-08-09",
    status: "Completed",
  },
];
