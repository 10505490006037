/** @format */
import Button from "@mui/material/Button";

const DownloadButton = ({ rows, columns, filename,text }: any) => {
  const downloadCSV = () => {
    const csvRows = [];
    const headerRow = columns.map((column: any) => column.headerName);
    csvRows.push(headerRow.join(','));

    rows.forEach((row: any) => {
      const rowData = columns.map((column: any) => {
        if (column.field === 'lineItem') {
          return JSON.stringify(row.lineItem);
        }
        return row[column.field];
      });
      csvRows.push(rowData.join(','));
    });
    // Construct the CSV content
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  return (
    <Button
      size="small"
      sx={{
        paddingLeft: `30px`,
        paddingRight: `30px`,
        height: `50px`,
        borderRadius: `50px`,
        textTransform: `inherit`,
        backgroundColor: "#FEB127",
        marginLeft:'20px',
        width: "max-content",
        font: `normal normal 500 20px/80px Raleway`,
        "&:hover, &:active": {
          backgroundColor: "#FEB127",
          color: "#fff",
        },
      }}
      variant="contained"
      onClick={downloadCSV}
    >
      {text}
    </Button>
  );
};

export default DownloadButton;
