/** @format */

import React from "react";
import { createRoot } from "react-dom/client";
import Routes from "./routes";
import { ThemeProvider } from "@mui/material/styles";
import "./submodule/styles/index.scss";
import { MuiThemeCustomized } from "./submodule/styles/mui/themeStyles";
import { QueryClient, QueryClientProvider } from "react-query";
import { defaultAxiosInterceptor } from "./shared/funcs";
import axios from "axios";
import MuiXLicense from "./components/MuiXLicense";

const container = document.getElementById("root")!;
const root = createRoot(container);

// default axios interceptor config
axios.interceptors.request.use(defaultAxiosInterceptor);

// initializing query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  },
});
root.render(
  <React.StrictMode>
    <ThemeProvider theme={MuiThemeCustomized}>
      <QueryClientProvider client={queryClient}>
        <Routes />
      </QueryClientProvider>
      <MuiXLicense />
    </ThemeProvider>
  </React.StrictMode>
);
