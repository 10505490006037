import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Modules } from "./submodule/constants/constants";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";

export const SideBarRoutesList = [
  {
    text: "Organizations",
    icon: GroupWorkOutlinedIcon,
    to: "/app/organizations",
    child: "organization",
    activeSideBar: Modules.Organization,
  },
  {
    text: "Logs",
    icon: NoteAltOutlinedIcon,
    to: "/app/logs",
    activeSideBar: "logs",
  },
  {
    text: "Settings",
    icon: SettingsIcon,
    to: "/app/setting",
    activeSideBar: "setting",
  },
];

export function getAuthorizationHeader() {
  return {
    Authorization: `Bearer ${sessionStorage.getItem("session_guid")}`,
  };
}

export const tagOptions = ["Completed", "Cancelled"];
