export const OrganizationColumnsData = [
  "Customer ID",
  "Display Name",
  "Is Active",
  "Created At",
  "Note",
  "Monthly Discount",
  "Installment Discount",
  "Annual Discount",
];

export const ActiveStatusData:{[key: number]: string} = {
  0: "Suspended",
  1: "Active",
}
