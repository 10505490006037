import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { resolveDynamicUrl } from "../shared/utils";
import { API_CACHE_KEYS, ApiRoutes } from "../constants/apiRoutes";
import { IOrganization } from "../shared/types";
import axios from "axios";

// export const useGetOrganizations = (
//   options?: UseQueryOptions<IOrganization[], null, IOrganization[]>
// ) => {
//   return useQuery<IOrganization[], null, IOrganization[]>(
//     API_CACHE_KEYS.ORGANIZATIONS,
//     async () => {
//       const { data } = await axios.get(ApiRoutes.organizations);
//       return data;
//     },
//     { ...options, placeholderData: [] }
//   );
// };
// Function with query param added

export const useGetOrganizations = (
  SearchBy?: string | string[],
  options?: UseQueryOptions<IOrganization[], null, IOrganization[]>
) => {
  return useQuery<IOrganization[], null, IOrganization[]>(
    API_CACHE_KEYS.ORGANIZATIONS,
    async () => {
      const { data } = await axios.get(ApiRoutes.organizations, {
        params: { SearchBy },
      });
      return data;
    },
    { ...options, placeholderData: [], retry: 0 }
  );
};

export const useUpdateOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: IOrganization) => {
      return axios.post(
        resolveDynamicUrl(ApiRoutes.updateOrganization, { organizationId }),
        data
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(API_CACHE_KEYS.ORGANIZATIONS);
      },
    }
  );
};
