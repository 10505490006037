import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";

import { changeLogColumn, innerChangeLogColumn } from "../rawData";
import CustomGrid from "../../../components/CustomGrid";
import { useGetChangeLogs } from "../../../api/useLogs";
import SearchBar from "../../../components/SearchBar/SearchBar";
import CustomDialogBox from "../../../components/CustomDialogBox";
import TopDialogBox, {
  TopDialogBoxProps,
} from "../../../components/TopDialogBox/TopDialogBox";

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: #44c2b233 !important;
    border: 2px solid #44c2b2 !important;
    margin: "5px";
  }

  .MuiChip-label {
    font: normal normal bold 14px/80px Raleway;
    letter-spacing: 0.42px;
    color: #44c2b2;
    text-transform: capitalize;
  }

  .MuiChip-deleteIcon path {
    color: #44c2b2;
  }
`;

const ChangeLogs = () => {
  const [selectedRowData, setSelectedRowData] =
    useState<TopDialogBoxProps | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const searchParam = searchValue.join(", ");
  const changeLogs = useGetChangeLogs(searchParam);

  const queryParams = searchValue.length > 0 ? searchValue.join("+") : "";
  const searchUrl = queryParams ? `?SearchBy=${queryParams}` : "/app/logs";

  const handleSearch = () => {
    if (inputValue.trim() !== "") {
      const trimmedInput = inputValue.trim();
      if (!searchValue.includes(trimmedInput)) {
        setSearchValue([...searchValue, trimmedInput]);
      }
      setInputValue("");
    }
  };

  useEffect(() => {
    window.history.replaceState({}, "", searchUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleChipDelete = (chipToDelete: string) => {
    setSearchValue(searchValue.filter((chip) => chip !== chipToDelete));
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };
  useEffect(() => {
    window.history.replaceState({}, "", "/app/logs");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // searchValue.length > 0 && changeLogs.refetch();
    changeLogs.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  //get Row id and Open Dialog Box
  const handleRowClick = (rowData: any) => {
    setDialogOpen(true);
    setSelectedRowData(rowData?.row);
  };
  //Closing Dialog Box
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const showColumns = {
    subscription_log_id: true,
    customer_display_name: true,
    user_first_name: true,
    change_timestamp: true,
    new_status: true,
    new_quantity: true,
    new_price: changeLogs?.data
      ?.map((item) => item?.new_price)
      .some((price) => price !== undefined)
      ? true
      : false,
  };

  const showInnerColumns = {
    subscription_log_id: true,
    customer_display_name: true,
    user_first_name: true,
    old_status: true,
    new_status: true,
    old_quantity: true,
    new_quantity: true,
    old_price: changeLogs?.data
      ?.map((item) => item?.old_price)
      .some((price) => price !== undefined)
      ? true
      : false,
    new_price: changeLogs?.data
      ?.map((item) => item?.new_price)
      .some((price) => price !== undefined)
      ? true
      : false,
  };

  return (
    <>
      <Box>
        <Grid container spacing={2} className="searchSection">
          <Grid item xs={12} md={6} lg={7} xl={8} sx={{ marginBottom: "10px" }}>
            <SearchBar
              value={inputValue}
              onChange={(e: any) => setInputValue(e.target.value)}
              onSearchClick={handleSearch}
              onKeyDown={handleEnterKeyPress}
            />
          </Grid>
        </Grid>
      </Box>
      {searchValue.map((chip, index) => (
        <StyledChip
          key={index}
          label={chip}
          onDelete={() => handleChipDelete(chip)}
          style={{ margin: "0 5px 10px 5px" }}
        />
      ))}
      <CustomGrid
        rows={changeLogs?.data || []}
        columns={changeLogColumn}
        handleRowClick={handleRowClick}
        loading={changeLogs?.isFetching}
        showToolbar={true}
        getRowId={(row: any) => row?.subscription_log_id}
        columnVisibilityModel={showColumns}
        sx={{
          height: 600,
          position: "static",
          width: "99%",
          ".MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          ".Mui-odd": { background: "#00000008" },
        }}
      />
      <CustomDialogBox open={dialogOpen} onClose={handleCloseDialog}>
        {selectedRowData && (
          <TopDialogBox
            id={selectedRowData.id}
            fullfillment_status={selectedRowData?.fullfillment_status}
            createUpdateLabel="Updated"
            isTotal={false}
            isStatus={false}
            item={selectedRowData.item}
            userEmail={selectedRowData?.user_principal_name}
            organization={selectedRowData?.customer_display_name}
            changed={selectedRowData.change_timestamp}
            innerRows={selectedRowData ? [selectedRowData] : []}
            innerColumns={innerChangeLogColumn}
            userID={selectedRowData?.user_display_name}
          />
        )}
        <CustomGrid
          sx={{
            height: 250,
            width: "99%",
            ".Mui-odd": { background: "#00000008" },
          }}
          rows={selectedRowData ? [selectedRowData] : []}
          columns={innerChangeLogColumn}
          showToolbar={false}
          getRowId={(row: any) => row?.subscription_log_id}
          columnVisibilityModel={showInnerColumns}
        />
      </CustomDialogBox>
    </>
  );
};

export default ChangeLogs;
