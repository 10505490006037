/** @format */
import SignIn from "../../submodule/components/SignIn/SignIn";
import { useHelloApi } from "../../api/useHello";
import { SSOApiRoutes } from "../../constants/apiRoutes";

const Login = (props: any) => {
  const { isFetching, isError } = useHelloApi();

  const attemptLogin = () => {
    window.location.href = SSOApiRoutes.signInPortal;
  };

  let userType = props.userType;
  userType = userType.toLowerCase().replace(/\b[a-z]/g, function (letter: any) {
    return letter.toUpperCase();
  });

  return (
    <SignIn
      attemptLogin={attemptLogin}
      userType={userType}
      error={isError}
      loading={isFetching}
    />
  );
};

export default Login;
