import React from 'react';
import {TextField, TextFieldProps} from "@mui/material";
import {NumericFormat, NumericFormatProps} from 'react-number-format';

type PropType = Omit<TextFieldProps, "value"|"onChange"> & NumericFormatProps;

const NumberInput = ({ value, onValueChange, ...rest }: PropType) => {
  return (
    <NumericFormat
      value={value}
      thousandSeparator={false}
      decimalScale={2}
      suffix={'%'}
      allowNegative={false}
      customInput={TextField as any}
      allowLeadingZeros={false}
      onValueChange={onValueChange}
      fixedDecimalScale
      displayType="input"
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue !== undefined) return floatValue >= 0.0 && floatValue <= 100.0;
        return false
      }}
      {...rest}
    />
  );
};

export default NumberInput;
