/** @format */
//@ts-nocheck
import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import MSButton from "../../submodule/components/MSButton/MSButton";
import DownloadButton from "../../submodule/components/DownloadButton/DownloadButton";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ListItem from "@mui/material/ListItem";
import { Button, TextField } from "@mui/material";
import { APP_ROUTES } from "../../constants/appRoutes";
import NumberInput from "../../components/Inputs/NumberInput/NumberInput";
import { IOrganization } from "../../shared/types";
import { ActiveStatusData, OrganizationColumnsData } from "../../shared/data";
import { useUpdateOrganization } from "../../api/useOrganizations";
import AlertMessage from "../../submodule/components/AlertMessage/AlertMessage";
import { convertGMTtoDateTime, resolveDynamicUrl } from "../../shared/utils";
import Loader from "../../components/Loader/Loader";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //...theme.typography.body2,
  padding: theme.spacing(1),
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const OrganizationDetailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { organizationId } = useParams<{ organizationId: string }>();
  const [messageAlert, setMessageAlert] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const { mutate, isLoading } = useUpdateOrganization(organizationId as string);
  const organizationDetail = location.state;

  const [isStatusActive, setActiveStatus] = useState(
    organizationDetail.is_active ? 1 : 0
  );

  const [formState, setFormState] = useState<IOrganization>(organizationDetail);
  const onUpdateFormState = (
    key: keyof IOrganization,
    value: IOrganization[typeof key]
  ) => {
    setFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const columns = OrganizationColumnsData;

  const onSaveClick = () => {
    mutate(formState, {
      onSuccess() {
        setActiveStatus(formState.is_active);
        setMessageAlert({
          message: "The records has been updated",
          type: "success",
        });
      },
      onError() {
        setMessageAlert({
          message: "Failed to update the record. Try again!",
          type: "error",
        });
      },
    });
  };

  const isSaving = isLoading;

  return (
    <div>
      <Box
        className="d-md-flex justify-content-md-between align-items-md-center"
        sx={{
          marginBottom: `10px`,
        }}
      >
        <h1>
          {organizationDetail.display_name}{" "}
          <span className={isStatusActive ? "ms-active" : "ms-suspend"}>
            ({ActiveStatusData[Number(isStatusActive)]})
          </span>
        </h1>
        <Box>
          <Button
            onClick={() =>
              navigate(
                resolveDynamicUrl(APP_ROUTES.ORDERLOGS, {
                  organizationId: organizationDetail.display_name,
                }),
                {
                  state: organizationDetail,
                }
              )
            }
            sx={{
              background: "#0A7ECA",
              color: "#fff",
              borderRadius: "38px",
              textTransform: "capitalize",
              "&:hover": { background: "#0A7ECA" },
              fontSize: { xl: "18px" },
              height: { xl: "50px" },
              padding: { xl: "5px 40px" },
            }}
          >
            Orders
          </Button>
          <DownloadButton
            rows={[organizationDetail]}
            columns={columns}
            filename="organizationDetail.csv"
          />
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Item className="d-md-flex align-items-md-center">
              <h3 className="ms-meta-title">Created On: </h3>
              <h3 className="ms-meta-data">
                {convertGMTtoDateTime(organizationDetail.created_at)}
              </h3>
            </Item>
          </Grid>
          <Grid item xs={12} md={12}>
            <Item className="d-md-flex align-items-md-center">
              <h3 className="ms-meta-title">Customer ID: </h3>
              <h3 className="ms-meta-data">
                {organizationDetail.customer_guid}
              </h3>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <div className="panel-light organization">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5}>
              <h1 className="ms-meta-title">Change Status </h1>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioGroup
                row
                defaultValue={formState.is_active}
                onChange={(e) =>
                  onUpdateFormState(
                    "is_active",
                    Number(e.target.value === "true")
                  )
                }
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radio-button-group"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Suspended"
                />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: "30px" }}>
            <Grid item xs={12} sm={5}>
              <h1 className="ms-meta-title">Monthly Discount: </h1>
            </Grid>
            <Grid item xs={12} md={7}>
              <NumberInput
                label="Monthly Discount %"
                variant="outlined"
                fullWidth
                defaultValue={formState.monthly_discount}
                onValueChange={(e) =>
                  onUpdateFormState("monthly_discount", e.floatValue as number)
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: "30px" }}>
            <Grid item xs={12} sm={5}>
              <h1 className="ms-meta-title">Installment Discount: </h1>
            </Grid>
            <Grid item xs={12} md={7}>
              <NumberInput
                label="Installment Discount %"
                variant="outlined"
                fullWidth
                defaultValue={formState.installment_discount}
                onValueChange={(e) =>
                  onUpdateFormState(
                    "installment_discount",
                    e.floatValue as number
                  )
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: "30px" }}>
            <Grid item xs={12} sm={5}>
              <h1 className="ms-meta-title">Annual Discount: </h1>
            </Grid>
            <Grid item xs={12} md={7}>
              <NumberInput
                label="Annual Discount %"
                variant="outlined"
                fullWidth
                defaultValue={formState.annual_discount}
                onValueChange={(e) =>
                  onUpdateFormState("annual_discount", e.floatValue as number)
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: "30px" }}>
            <Grid item xs={12} sm={5}>
              <h1 className="ms-meta-title">Notes </h1>
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                multiline
                fullWidth
                rows={5}
                maxRows={10}
                inputProps={{ maxLength: 2000 }}
                label="Notes"
                variant="outlined"
                defaultValue={formState.note}
                onChange={(e) => onUpdateFormState("note", e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ marginTop: "20px" }}
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <Grid item>
              <ListItem
                component={Link}
                to={APP_ROUTES.APP_ORGANIZATION}
                sx={{ padding: 0 }}
              >
                <MSButton
                  text="Back"
                  backgroundColor="#9BA4AF"
                  icon={<ArrowBackIosIcon />}
                />
              </ListItem>
            </Grid>

            <Grid item>
              <MSButton clickAction={onSaveClick} disabled={isSaving}>
                <>
                  {isSaving ? (
                    <Box>
                      <Loader size="1em" color="inherit" />
                    </Box>
                  ) : (
                    ""
                  )}
                  &nbsp;Save
                </>
              </MSButton>
            </Grid>
          </Grid>
        </div>
      </Box>
      {messageAlert &&
        AlertMessage(true, messageAlert.message, messageAlert.type, () =>
          setMessageAlert(null)
        )}
    </div>
  );
};

export default OrganizationDetailPage;
