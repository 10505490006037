import { getImageProxy as getRouteProxy } from "../submodule/utils/utils";

export const ApiConfig = {
  BASE_URL: process.env.REACT_APP_ADMIN_API_BASE,
  SSO_BASE_URL: `${process.env.REACT_APP_API_BASE}/sso/v1`,
};

const ApiRoutesList = {
  hello: "hello",
  organizations: "organizations",
  notifyPortalMessage: "notify/v1/portalmessage",
  addOrganizationDiscount: "organization/{organizationId}/discounts",
  updateOrganization: "organization/{organizationId}",
  apps: "apps",
  updateApp: "app/{appId}",
  orderLogs: `orderlog`,
  changeLogs: `subscriptionchangelog`,
};

const SSOApiRoutesList = {
  signInPortal: "signin/cspadmin",
};

export const API_CACHE_KEYS = {
  ORGANIZATIONS: "ORGANIZATIONS",
  SIGN_IN_PORTAL: "SIGN_IN_PORTAL",
  SIGN_OUT_PORTAL: "SIGN_OUT_PORTAL",
  PROFILE_PORTAL: "PROFILE_PORTAL",
  PROFILE: "PROFILE",
  REFRESH: "REFRESH",
  NOTIFY_PORTAL_MESSAGE: "NOTIFY_PORTAL_MESSAGE",
  ADD_ORGANIZATION_DISCOUNT: "ADD_ORGANIZATION_DISCOUNT",
  APP: "APP_SETTINGS",
  ORDERLOGS: "ORDERLOGS",
  CHANGELOGS: "CHANGELOGS",
};

export const ApiRoutes = getRouteProxy(
  ApiConfig.BASE_URL as string,
  ApiRoutesList
) as typeof ApiRoutesList;
export const SSOApiRoutes = getRouteProxy(
  ApiConfig.SSO_BASE_URL as string,
  SSOApiRoutesList
) as typeof SSOApiRoutesList;
